<template lang="pug">
v-container
    v-card(light)
        v-card-title Configure
            span(style="color:blue;").ml-2 {{ systemName }}
        v-card-text
            v-row
                v-col(cols="12" sm="6" md="4")
                    v-form(ref="predictionCategoryForm")
                        v-select(
                            v-model="selectedPredictionCategory"
                            :items="categoryNames"
                            label="Select Prediction Category"
                            item-text="category_name"
                            item-value="id"
                            color="brand"
                            @change="updatePredictionCategory"
                        )
            v-row(class="mb-2")
                v-col(cols="3")
                    strong Category Name
                v-col(cols="3")
                    strong UOM
                v-col(cols="3")
                    strong(style="margin-left: -10px") Finalized
                v-col(cols="3")
                    strong.ml-0 Predict
            v-expansion-panels
                v-expansion-panel(v-for="category in localCategories" :key="category.id")
                    v-expansion-panel-header(ripple @click="copyCategoryToEdit(category)")
                        v-row
                            v-col(cols="3")
                                | {{ category.category_name }}
                            v-col(cols="3")
                                | {{ getUomName(category.common_uom_id) }}
                            v-col(cols="3")
                                v-icon(:color="category.is_finalized ? 'green' : 'red'")
                                    | {{ category.is_finalized ? 'check' : 'close' }}
                            v-col(cols="3")
                                v-icon(:color="category.is_prediction_category ? 'blue' : 'grey'")
                                    | {{ category.is_prediction_category ? 'check' : 'close' }}
                    v-expansion-panel-content(style="border-top: 3px solid #c0c0c0; padding-top: 16px;")
                        div
                            v-form(:ref="'editCatForm_' + category.id" v-model="category.valid")
                                v-row(dense)
                                    v-col(cols="12" sm="9" md="8")
                                        v-text-field(
                                            v-model.trim="editedItem.category_name"
                                            label="Category Name (read only)"
                                            :rules="[rules.required, value => noDuplicate(value, editedItem.category_name)]"
                                            color="brand"
                                            required
                                            readonly
                                        )
                                    v-col(cols="12" sm="6" md="4")
                                        v-select(
                                            v-model="editedItem.common_uom_id"
                                            :items="localUom"
                                            item-text="uom_code"
                                            item-value="id"
                                            :rules="[rules.required, value => isValidCategoryUom(value)]"
                                            label="UOM"
                                            required
                                            color="brand"
                                        )

                                v-row(v-if="editedItem.is_finalized" dense)
                                    v-col(cols="12" sm="9" md="8")
                                        v-text-field(
                                            v-model="editedItem.finalized_by"
                                            label="Finalized By",
                                            readonly
                                            color="brand"
                                        )
                                    v-col(cols="12" sm="9" md="8")
                                        v-text-field(
                                            :value="formattedFinalizedDate"
                                            label="Finalized On",
                                            readonly
                                            color="brand"
                                        )

                        div.mt-5
                            Points(
                                :category="category",
                                :uom="localUom",
                                @update:points="updatePoints"
                            )
                        div.mt-5
                            v-row
                                v-col(cols="12")
                                    template(v-if="canFinalize(category)")
                                        v-btn(
                                            color="green"
                                            :disabled="!category.valid"
                                            @click="finalizeCategory(category)"
                                            class="mr-3"
                                        ) Finalize {{ category.category_name }}
                                    template(v-else)
                                        v-btn(
                                            color="orange"
                                            @click="unFinalizeCategory(category)"
                                            class="mr-3"
                                        ) UnFinalize {{ category.category_name }}
                                    v-btn(
                                        color="blue"
                                        @click="saveCategory"
                                        class="mr-3"
                                    ) Save {{ category.category_name }}
                                v-row
                                    v-col(cols="12").ml-3
                                        span {{ category.valid }}


        v-card-actions
            v-spacer
            v-btn(color="red", @click="closeConfig") close

</template>

<script>

// Only system save should write to the server
// all other changes should be local and then to the store state
// An over all save button will then run a store action to save to the server

import mergeDeep from 'lodash/merge';
import Points from './Points.vue';

export default {
    name: 'Categories',
    emits: ['update:categories', 'close:categories'],
    components: {
        Points
    },
    props: {
        categories: {
            type: Array,
            required: true
        },
        systemName: {
            type: String,
            required: true
        },
        systemId: {
            type: String,
            required: true
        },
        uom: {
            type: Array,
            required: true
        }
    },
    data () {
        return {
            selectedPredictionCategory: null,
            // openPoints: false,
            localCategories: mergeDeep([], this.categories).map(cat => {
                cat.valid = false;
                return cat;
            }),
            localUom: [...this.uom],
            headers: [
                { text: 'Category', value: 'category_name' },
                { text: 'UOM', value: 'common_uom_id' },
                { text: 'Finalized', value: 'is_finalized' },
                { text: 'Finalized By', value: 'finalized_by' },
                { text: 'Predict', value: 'is_prediction_category' },
                { text: 'Actions', value: 'actions', sortable: false },
            ],
            defaultCategory: {
                category_name: '',
                category_type: 'FLOW',
                common_uom_id: 1,
                finalized_by: null,
                is_finalized: false,
                system_id: this.systemId,
                is_prediction_category: false,
                valid: false
            },
            // editCatDialog: false,
            editedItem: { ...this.defaultCategory },
            newItem: null,
            rules: {
                required: (value) => !!value || 'Required.',
            },
        };
    },
    methods: {
        updatePredictionCategory (selectedId) {
            // Iterate through categories to update the isPredictionCategory flag
            this.localCategories.forEach(category => {
                category.is_prediction_category = category.id === selectedId;
            });

            this.$emit('update:categories', mergeDeep([], this.localCategories));
        },

        closeConfig () {
            this.$emit('close:categories');
        },

        deleteCategory (category) {
            console.log('Deleting category:', category.category_name);
            // this.$set(this, 'localCategories', this.localCategories.filter(cat => cat.id !== category.id));
        },

        getUomName (id){
            const uom = this.localUom.find(u => u.id === id);
            return uom ? uom.uom_code : '';
        },

        copyCategoryToEdit (category) {
            console.log('Copying category to edit:', category.category_name);
            this.editedItem = { ...category };
            this.newCatItem = false;
            // Wait for the DOM to indicate the panel content is rendered
            const formRef = `editCatForm_${category.id}`;
            const waitForRender = setInterval(() => {
                const form = this.$refs[formRef]?.[0];
                if (form) {
                    clearInterval(waitForRender); // Stop checking
                    console.log(`Form '${formRef}' is ready.`);
                    const isValid = form.validate(); // Trigger validation
                    this.$set(category, 'valid', isValid);
                    console.log('Validating form:', category);
                } else {
                    console.log(`Waiting for '${formRef}' to render...`);
                }
            }, 50); // Check every 50ms
        },

        noDuplicate (value, currentCategoryName) {
            const exists = this.localCategories.find(cat => cat.category_name === value && cat.category_name !== currentCategoryName);
            return !exists || 'Category already exists';
        },

        saveCategory () {
            const formRef = this.$refs[`editCatForm_${this.editedItem.id}`];
            if (formRef && formRef[0]) {
                const isValid = formRef[0].validate();
                if(!isValid) {
                    console.error('Form is not valid:', formRef);
                    return;
                }
                console.log('Saving category:', this.editedItem);
                if (this.newCatItem) {
                    this.localCategories = [...this.localCategories, this.editedItem];
                } else {
                    const index = this.localCategories.findIndex(cat => cat.id === this.editedItem.id);
                    this.localCategories[index] = mergeDeep({}, this.editedItem);
                }
                // update the store, via parent component
                // TODO: emitting the categories causes any unsaved changes to points to be lost
                this.$emit('update:categories', mergeDeep([], this.localCategories));
                formRef[0].validate();
            } else {
                console.error('Form not available:', formRef);
            }
        },

        /**
         *
         * @param {object} payload
         * @param {object} payload.category_id
         * @param {{}[]} payload.points
         */
        updatePoints (payload) {
            // find the index of our point in this.localCategories
            const catIndex = this.localCategories.findIndex(cat => cat.id === payload.category_id);
            this.$set(this.localCategories[catIndex], 'points', payload.points);
            this.$emit('update:categories', mergeDeep([], this.localCategories));
        },

        updateUser () {
            if(this.editedItem.is_finalized) {
                this.editedItem.finalized_by = this.$store.state?.fullUser?.email || 'Unknown';
            } else {
                this.editedItem.finalized_by = null;
            }
        },

        // showPoints (category) {
        //     console.log('Showing points for:', category.category_name);
        //     this.openPoints = true;
        // },

        /**
         * @param {object} category
         */
        finalizeCategory (category) {
            this.$set(this.editedItem, 'is_finalized', true);
            this.$set(this.editedItem, 'finalized_by', this.$store.state?.fullUser?.email || 'Unknown');
            this.saveCategory();
        },

        /**
         * @param {object} category
         */
        unFinalizeCategory (category) {
            if(this.editedItem.is_finalized) {
                console.log('Unfinalizing category:', category.category_name);
                this.$set(this.editedItem, 'is_finalized', false);
                this.$set(this.editedItem, 'finalized_by', null);
                this.saveCategory();
            }
        },

        isValidCategoryUom (uom_id) {
            const units = this.uom.filter(unit => unit.type === 'RATE' || unit.type === 'VOLUME');
            const isValid = units.some(unit => unit.id === uom_id);
            if (!isValid) {
                return `Unit of measure must be of type RATE or VOLUME`;
            }
            return true;
        },

        setSelectedPredictionCategory () {
            const predictionCategory = this.localCategories.find(category => category.is_prediction_category);
            this.selectedPredictionCategory = predictionCategory ? predictionCategory.id : null;
        },

        canFinalize (category) {
            const points = category.points || [];
            const somePointsValid = points.some(point => point.is_finalized);
            const categoryNotAlreadyFinalized = !category.is_finalized;
            const categoryHasValidUOM = category.common_uom_id > 1;
            console.log('Can finalize:', category.category_name, somePointsValid, categoryNotAlreadyFinalized, categoryHasValidUOM);
            return somePointsValid && categoryNotAlreadyFinalized && categoryHasValidUOM;
        },
    },
    computed: {
        dialogTitle () {
            return this.newCatItem ? 'Add Category' : 'Edit Category';
        },
        isPredictionCategorySet () {
            return this.localCategories.some(category => category.is_prediction_category);
        },
        categoryNames () {
            return this.localCategories.map(category => ({
                category_name: category.category_name,
                id: category.id,
            }));
        },
        formattedFinalizedDate () {
            return this.editedItem.finalized_date
                ? new Date(this.editedItem.finalized_date).toLocaleString()
                : '';
        }
    },
    watch: {
        categories: {
            handler (newVal) {
                console.log('Categories changed:');
                if(newVal){
                    this.localCategories = mergeDeep([], this.categories).map(cat => {
                        cat.valid = false;
                        return cat;
                    }),
                    this.setSelectedPredictionCategory();
                }
            },
            immediate: true,
            deep: true
        },
        uom (newVal) {
            this.localUom = [...newVal];
        }
    },
    mounted () {
        console.log('Categories mounted with systemId:', this.systemId);
        this.localCategories = mergeDeep([], this.categories);
        this.setSelectedPredictionCategory();
    }
};
</script>

<style scoped>
/* Add any component-specific styles here */
</style>
